import Vuex from "vuex";
import expensesImage from "@/assets/receipt.png";

import { api } from "@/axiosConfig";

const store = new Vuex.Store({
  state: {
    selectedKeys: ["1"],
    isAddDepartmentVisible: false,
    isAddBranchVisible: false,
    isUpdateBranchVisible: false,
    isAddLeaveCodeVisible: false,
    isAddSalarySchemeVisible: false,
    isUpdateLeaveCodeVisible: false,
    isUpdateSalarySchemeVisible: false,
    isAddLeavePolicyVisible: false,
    isUpdateLeavePolicyVisible: false,
    isUpdateNationalityVisible: false,
    isUpdateReligionVisible: false,
    isAddNationalityVisible: false,
    isAddReligionVisible: false,
    isUpdateDepartmentVisible: false,
    isAddRaceVisible: false,
    isAddTimeLogVisible: false,
    isUpdateTimeLogVisible: false,
    isAddLeaveTransactionVisible: false,
    isUpdateLeaveTransactionVisible: false,
    isAddExpensesVisible: false,
    isUpdateExpensesVisible: false,
    isUpdateRaceVisible: false,
    isAddAddressVisible: false,
    isUpdateAddressVisible: false,
    isAddJobGradeVisible: false,
    isUpdateJobGradeVisible: false,
    isAddCostCenterVisible: false,
    isUpdateCostCenterVisible: false,
    isAddCountryVisible: false,
    isUpdateCountryVisible: false,
    saveSuccess: false,
    action: "",
    departmentAction: "",
    branchAction: "",
    jobGradeAction: "",
    costCenterAction: "",
    raceAction: "",
    leaveCodeAction: "",
    leavePolicyAction: "",
    nationalityAction: "",
    religionAction: "",
    salarySchemeAction: "",
    countryAction: "",
    leaveHistoryAction: "",
    addressAction: "",
    editLeaveRecord: "",
    editLeavePolicyRecord: "",
    timeLogAction: "",
    dateBgn: "",
    dateEnd: "",
    merchant: "",
    month: "",
    leaveTransactionAction: "",
    expensesEntryAction: "",
    expensesTotalAmount: "0.00",
    loading: false,
    departments: [],
    workGroups: [],
    races: [],
    branches: [],
    leaveCodes: [],
    leavePolicyList: [],
    nationlityList: [],
    religionList: [],
    employeeList: [],
    foreignEmployeeList: [],
    leaveHistory: [],
    salarySchemeList: [],
    addressList: [],
    jobGradeList: [],
    costCenterList: [],
    countryList: [],
    merchantList: [],
    companyList: [],
    timeLogList: [],
    timeSheetList: [],
    leaveList: [],
    endMonthList: [],
    expensesList: [],
    expensesTypeList: [],
    expensesPic: [],
    login:[],
    printReportList:[],
    selectedLeaveCodeDetails: null,
    selectedLeavePolicyDetails: null,
    selectedNationalityDetails: null,
    isUpdateLeaveHistoryVisible: false,
    selectedLeaveHistoryDetails: null,
    selectedDepartmentDetails: null,
    selectedReligionDetails: null,
    selectedSalarySchemeDetails: null,
    selectedAddressDetails: null,
    selectedJobGradeDetails: null,
    selectedCostCenterDetails: null,
    selectedCountryDetails: null,
    selectedRaceDetails: null,
    selectedBranchDetails: null,
    selectedTimeLogDetails: null,
    previousTimeLogDetails: null,
    selectedLeaveDetails: null,
    previousLeaveDetails: null,
    selectedExpensesDetails: null,
    previousExpensesDetails: null,
    selectedExpensesPicDetails: null,
  },
  getters: {},
  mutations: {
    toggleAddDepartmentVisibility(state, isVisible) {
      state.isAddDepartmentVisible = isVisible;
    },
    toggleAddBranchVisibility(state, isVisible) {
      state.isAddBranchVisible = isVisible;
    },
    toggleAddRaceVisibility(state, isVisible) {
      state.isAddRaceVisible = isVisible;
    },
    toggleAddCountryVisibility(state, isVisible) {
      state.isAddCountryVisible = isVisible;
    },
    toggleAddJobGradeVisibility(state, isVisible) {
      state.isAddJobGradeVisible = isVisible;
    },
    toggleUpdateDepartmentVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateDepartmentVisible = isVisible;
      state.departmentAction = action;
    },
    toggleUpdateJobGradeVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateJobGradeVisible = isVisible;
      state.jobGradeAction = action;
    },
    toggleUpdateBranchVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateBranchVisible = isVisible;
      state.branchAction = action;
    },
    toggleUpdateRaceVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateRaceVisible = isVisible;
      state.raceAction = action;
    },
    toggleAddLeaveCodeVisibility(state, isVisible) {
      console.log(isVisible);
      state.isAddLeaveCodeVisible = isVisible;
    },
    toggleAddSalarySchemeVisibility(state, isVisible) {
      console.log(isVisible);
      state.isAddSalarySchemeVisible = isVisible;
    },
    toggleAddAddressVisibility(state, isVisible) {
      console.log(isVisible);
      state.isAddAddressVisible = isVisible;
    },
    toggleUpdateSalarySchemeVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateSalarySchemeVisible = isVisible;
      state.salarySchemeAction = action;
    },
    toggleUpdateCountryVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateCountryVisible = isVisible;
      state.countryAction = action;
    },
    toggleUpdateAddressVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateAddressVisible = isVisible;
      state.addressAction = action;
    },
    toggleUpdateLeaveCodeVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateLeaveCodeVisible = isVisible;
      state.leaveCodeAction = action;
    },
    toggleAddLeavePolicyVisibility(state, isVisible) {
      console.log(isVisible);
      state.isAddLeavePolicyVisible = isVisible;
    },
    toggleAddNationalityVisibility(state, isVisible) {
      console.log(isVisible);
      state.isAddNationalityVisible = isVisible;
    },
    toggleAddReligionVisibility(state, isVisible) {
      console.log(isVisible);
      state.isAddReligionVisible = isVisible;
    },
    toggleAddCostCenterVisibility(state, isVisible) {
      console.log(isVisible);
      state.isAddCostCenterVisible = isVisible;
    },
    toggleAddTimeLogVisibility(state, isVisible) { //lxy
      console.log(isVisible);
      state.isAddTimeLogVisible = isVisible;
    },
    toggleAddLeaveTransactionVisibility(state, isVisible) { //lxy
      console.log(isVisible);
      state.isAddLeaveTransactionVisible = isVisible;
    },
    toggleAddExpensesVisibility(state, isVisible) { //lxy
      console.log(isVisible);
      state.isAddExpensesVisible = isVisible;
    },
    toggleUpdateLeavePolicyVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateLeavePolicyVisible = isVisible;
      state.leavePolicyAction = action;
    },
    toggleUpdateCostCenterVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateCostCenterVisible = isVisible;
      state.costCenterAction = action;
    },
    toggleUpdateNationalityVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateNationalityVisible = isVisible;
      state.nationalityAction = action;
    },
    toggleUpdateReligionVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateReligionVisible = isVisible;
      state.religionAction = action;
    },
    toggleUpdateLeaveHistoryVisibility(state, payload) {
      const { isVisible, action } = payload;
      state.isUpdateLeaveHistoryVisible = isVisible;
      state.leaveHistoryAction = action;
    },
    toggleUpdateTimeLogVisibility(state, payload) { //lxy
      const { isVisible, action } = payload;
      state.isUpdateTimeLogVisible = isVisible;
      state.timeLogAction = action;
    },
    toggleUpdateLeaveTransactionVisibility(state, payload) { //lxy
      const { isVisible, action } = payload;
      state.isUpdateLeaveTransactionVisible = isVisible;
      state.leaveTransactionAction = action;
    },
    toggleUpdateExpensesVisibility(state, payload) { //lxy
      const { isVisible, action } = payload;
      state.isUpdateExpensesVisible = isVisible;
      state.expensesEntryAction = action;
    },
    setDepartments(state, departments) {
      state.departments = departments; // Mutation to update departments state
    },
    setWorkGroups(state, workgroups) {
      state.workGroups = workgroups; // Mutation to update departments state
    },
    setBranchess(state, branches) {
      state.branches = branches; // Mutation to update departments state
    },
    setRaces(state, races) {
      state.races = races; // Mutation to update departments state
    },
    setLeaveCodes(state, leaveCodes) {
      console.log(leaveCodes);
      state.leaveCodes = leaveCodes; // Mutation to update leaveCodes state
    },
    setSalarySchemes(state, salarySchemes) {
      console.log(salarySchemes);
      state.salarySchemeList = salarySchemes; // Mutation to update leaveCodes state
    },
    setAddress(state, addresses) {
      console.log(addresses);
      state.addressList = addresses; // Mutation to update leaveCodes state
    },

    setLeavePolicyList(state, leavepolicy) {
      console.log("leavepolicy", leavepolicy);
      state.leavePolicyList = leavepolicy; // Mutation to update leaveCodes state
    },
    setJobGradesList(state, jobGrades) {
      console.log("jobGrades", jobGrades);
      state.jobGradeList = jobGrades; // Mutation to update leaveCodes state
    },
    setCountryList(state, countries) {
      console.log("countries", countries);
      state.countryList = countries; // Mutation to update leaveCodes state
    },

    setCostCenterList(state, costCenters) {
      console.log("costCenters", costCenters);
      state.costCenterList = costCenters; // Mutation to update leaveCodes state
    },

    setNationalityList(state, nations) {
      console.log("nationalities", nations);
      state.nationlityList = nations; // Mutation to update leaveCodes state
    },
    setReligionList(state, religions) {
      console.log("religions", religions);
      state.religionList = religions; // Mutation to update leaveCodes state
    },
    setEmployeeList(state, employees) {
      console.log("employees", employees);
      state.employeeList = employees; // Mutation to update leaveCodes state
    },
    setForeignEmployeeList(state, employees) {
      console.log("employees", employees);
      state.foreignEmployeeList = employees; // Mutation to update leaveCodes state
    },
    setLeaveHistory(state, leaves) {
      console.log("leaves", leaves);
      state.leaveHistory = leaves; // Mutation to update leaveCodes state
    },
    setLoading(state, isLoading) {
      console.log("setLoading mutation", isLoading);
      state.loading = isLoading;
    },
    setSelectedKeys(state, keys) {
      state.selectedKeys = keys;
    },
    setMerchantList(state, merchantProfile) { //lxy
      console.log("merchant", merchantProfile);
      state.merchantList = merchantProfile;
    },
    setCompanyList(state, merchantProfile) { //lxy
      console.log("company", merchantProfile);
      state.companyList = merchantProfile;
    },
    setTimeLogList(state, timeLog) { //lxy
      console.log("timeLog", timeLog);
      state.timeLogList = timeLog;
    },
    setTimeSheetList(state, timeSheet) { //lxy
      console.log("timeSheet", timeSheet);
      state.timeSheetList = timeSheet;
    },
    setChangeDateFromValue(state, value) { //lxy
      console.log("from", value)
      state.dateBgn = value;
    },
    setChangeDateToValue(state, value) { //lxy
      console.log("to", value)
      state.dateEnd = value;
    },
    setMerchantNo(state, value) { //lxy
      console.log("merchant set", value)
      state.merchant = value;
    },
    setChangeMonthValue(state, value) { //lxy
      console.log("month", value)
      state.month = value;
    },
    setLeaveList(state, leave) { //lxy
      console.log("leave", leave);
      state.leaveList= leave;
    },
    setEndMonthList(state, endMonth) { //lxy
      console.log("endMonth", endMonth);
      state.endMonthList = endMonth;
    },
    setExpensesList(state, expenses) { //lxy
      console.log("expenses", expenses);
      state.expensesList = expenses;
    },
    setExpensesTypeList(state, expensesType) { //lxy
      console.log("expensesType", expensesType);
      state.expensesTypeList = expensesType;
    },
    setExpensesTotalAmount(state, expenses) { //lxy
      console.log("expensesTotalAmount", expenses);
      state.expensesTotalAmount = expenses;
    },
    setExpensesPicList(state, expensesPic) { //lxy
      console.log("expensesPic", expensesPic);
      state.expensesPic = expensesPic;
    },
    setLogin(state, login) { //lxy
      console.log("login", login);
      state.login = login;
    },
    setPrintReport(state, printReport) { //lxy
      console.log("printReport", printReport);
      state.printReportList = printReport;
    },
    updateLeaveCodeEditRecord(state, editLeaveRecord) {
      console.log("updateLeaveCodeEditRecord mutation", editLeaveRecord);
      state.editLeaveRecord = editLeaveRecord;
    },
    updateLeavePolicyEditRecord(state, editLeavePolicyRecord) {
      console.log(
        "updateLeavePolicyEditRecord mutation",
        editLeavePolicyRecord
      );
      state.editLeavePolicyRecord = editLeavePolicyRecord;
    },
    updateSelectedLeaveCode(state, payload) {
      console.log(
        "updateSelectedLeaveCode mutation",
        payload.leaveCodeDetails,
        payload.action
      );
      const leaveTypeOptions = [
        { value: "Annual", label: "A" },
        { value: "Hospitalization", label: "H" },
        { value: "Medical", label: "M" },
        { value: "Others", label: "O" },
      ];
      const leaveSignOptions = [
        { value: "Add(+)", label: "+" },
        { value: "Deduct(-)", label: "-" },
      ];
      const editdate = new Date(payload.leaveCodeDetails.edit_date);
      const dd = String(editdate.getDate()).padStart(2, "0");
      const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = editdate.getFullYear();
      payload.leaveCodeDetails.edit_date = `${dd}/${mm}/${yyyy}`;
      console.log(2);
      const createdate = new Date(payload.leaveCodeDetails.create_date);
      const dd1 = String(createdate.getDate()).padStart(2, "0");
      const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy1 = createdate.getFullYear();
      payload.leaveCodeDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;
      console.log(3);
      const signValue = leaveSignOptions.find(
        (option) => option.label === payload.leaveCodeDetails.leave_sign
      );
      console.log(signValue);
      payload.leaveCodeDetails.leave_sign = signValue.value;
      const typeValue = leaveTypeOptions.find(
        (option) => option.label === payload.leaveCodeDetails.leave_type
      );
      payload.leaveCodeDetails.leave_type = typeValue.value;

      state.selectedLeaveCodeDetails = payload.leaveCodeDetails;

      store.dispatch("toggleUpdateLeaveCodeVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedSalaryScheme(state, payload) {
      console.log(
        "updateSelectedSalaryScheme mutation",
        payload.salarySchemeDetails,
        payload.action
      );
      const schemeTypeOptions = [
        { value: "Monthly", label: "M" },
        { value: "Daily", label: "D" },
        { value: "Hourly", label: "H" },
      ];

      const editdate = new Date(payload.salarySchemeDetails.edit_date);
      const dd = String(editdate.getDate()).padStart(2, "0");
      const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = editdate.getFullYear();
      payload.salarySchemeDetails.edit_date = `${dd}/${mm}/${yyyy}`;
      console.log(2);
      const createdate = new Date(payload.salarySchemeDetails.create_date);
      const dd1 = String(createdate.getDate()).padStart(2, "0");
      const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy1 = createdate.getFullYear();
      payload.salarySchemeDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;
      console.log(3);
      const schemeTypeValue = schemeTypeOptions.find(
        (option) =>
          option.label === payload.salarySchemeDetails.salary_scheme_type
      );
      console.log(schemeTypeValue);
      payload.salarySchemeDetails.salary_scheme_type = schemeTypeValue.value;

      state.selectedSalarySchemeDetails = payload.salarySchemeDetails;

      store.dispatch("toggleUpdateSalarySchemeVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedAddress(state, payload) {
      console.log(
        "updateSelectedAddress mutation",
        payload.addressDetails,
        payload.action
      );

      const editdate = new Date(payload.addressDetails.edit_date);
      const dd = String(editdate.getDate()).padStart(2, "0");
      const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = editdate.getFullYear();
      payload.addressDetails.edit_date = `${dd}/${mm}/${yyyy}`;
      console.log(2);
      const createdate = new Date(payload.addressDetails.create_date);
      const dd1 = String(createdate.getDate()).padStart(2, "0");
      const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy1 = createdate.getFullYear();
      payload.addressDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;

      state.selectedAddressDetails = payload.addressDetails;

      store.dispatch("toggleUpdateAddressVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedLeavePolicyCode(state, payload) {
      const editdate = new Date(payload.leavePolicyDetails.edit_date);
      const dd = String(editdate.getDate()).padStart(2, "0");
      const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = editdate.getFullYear();
      payload.leavePolicyDetails.edit_date = `${dd}/${mm}/${yyyy}`;

      const createdate = new Date(payload.leavePolicyDetails.create_date);
      const dd1 = String(createdate.getDate()).padStart(2, "0");
      const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy1 = createdate.getFullYear();
      payload.leavePolicyDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;
      state.selectedLeavePolicyDetails = payload.leavePolicyDetails;
      store.dispatch("toggleUpdateLeavePolicyVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedNationality(state, payload) {
      const editdate = new Date(payload.nationalityDetails.edit_date);
      const dd = String(editdate.getDate()).padStart(2, "0");
      const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = editdate.getFullYear();
      payload.nationalityDetails.edit_date = `${dd}/${mm}/${yyyy}`;

      const createdate = new Date(payload.nationalityDetails.create_date);
      const dd1 = String(createdate.getDate()).padStart(2, "0");
      const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy1 = createdate.getFullYear();
      payload.nationalityDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;
      state.selectedNationalityDetails = payload.nationalityDetails;
      store.dispatch("toggleUpdateNationalityVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedReligion(state, payload) {
      console.log("updateSelectedReligion:", payload);
      const editdate = new Date(payload.religionDetails.edit_date);
      const dd = String(editdate.getDate()).padStart(2, "0");
      const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = editdate.getFullYear();
      payload.religionDetails.edit_date = `${dd}/${mm}/${yyyy}`;

      const createdate = new Date(payload.religionDetails.create_date);
      const dd1 = String(createdate.getDate()).padStart(2, "0");
      const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy1 = createdate.getFullYear();
      payload.religionDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;
      state.selectedReligionDetails = payload.religionDetails;
      store.dispatch("toggleUpdateReligionVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedJobGrade(state, payload) {
      console.log("updateSelectedJobGrade:", payload);
      const editdate = new Date(payload.jobGradeDetails.edit_date);
      const dd = String(editdate.getDate()).padStart(2, "0");
      const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = editdate.getFullYear();
      payload.jobGradeDetails.edit_date = `${dd}/${mm}/${yyyy}`;

      const createdate = new Date(payload.jobGradeDetails.create_date);
      const dd1 = String(createdate.getDate()).padStart(2, "0");
      const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy1 = createdate.getFullYear();
      payload.jobGradeDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;
      state.selectedJobGradeDetails = payload.jobGradeDetails;
      store.dispatch("toggleUpdateJobGradeVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedCountry(state, payload) {
      console.log("updateSelectedJobGrade:", payload);
      const editdate = new Date(payload.countryDetails.edit_date);
      const dd = String(editdate.getDate()).padStart(2, "0");
      const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = editdate.getFullYear();
      payload.countryDetails.edit_date = `${dd}/${mm}/${yyyy}`;

      const createdate = new Date(payload.countryDetails.create_date);
      const dd1 = String(createdate.getDate()).padStart(2, "0");
      const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy1 = createdate.getFullYear();
      payload.countryDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;
      state.selectedCountryDetails = payload.countryDetails;
      store.dispatch("toggleUpdateCountryVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedCostCenter(state, payload) {
      console.log("updateSelectedCostCenter:", payload);
      const editdate = new Date(payload.costCenterDetails.edit_date);
      const dd = String(editdate.getDate()).padStart(2, "0");
      const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = editdate.getFullYear();
      payload.costCenterDetails.edit_date = `${dd}/${mm}/${yyyy}`;

      const createdate = new Date(payload.costCenterDetails.create_date);
      const dd1 = String(createdate.getDate()).padStart(2, "0");
      const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy1 = createdate.getFullYear();
      payload.costCenterDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;
      state.selectedCostCenterDetails = payload.costCenterDetails;
      store.dispatch("toggleUpdateCostCenterVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedLeaveHistory(state, payload) {
      console.log("updateSelectedLeaveHistory:", payload);
      state.selectedLeaveHistoryDetails = payload.leaveHistoryDetails;
      store.dispatch("toggleUpdateLeaveHistoryVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedDepartment(state, payload) {
      console.log("updateSelectedDepartment:", payload);
      state.selectedDepartmentDetails = payload.departmentDetails;
      store.dispatch("toggleUpdateDepartmentVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedBranch(state, payload) {
      console.log("updateSelectedBranch:", payload);
      state.selectedBranchDetails = payload.branchDetails;
      store.dispatch("toggleUpdateBranchVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedRace(state, payload) {
      console.log("updateSelectedRace:", payload);
      state.selectedRaceDetails = payload.raceDetails;
      store.dispatch("toggleUpdateRaceVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedTimeLog(state, payload) {
      console.log(
        "updateSelectedTimeLog mutation",
        payload.timeLogDetails,
        payload.action
      );

      const today = new Date(payload.timeLogDetails.clock_date);
      const year = today.getFullYear();
      const month =
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : `${today.getMonth() + 1}`;
      const day =
        today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`;
      payload.timeLogDetails.clock_date = `${year}-${month}-${day}`;
      console.log(payload.timeLogDetails.clock_date)
      payload.timeLogDetails.empno = `${payload.timeLogDetails.empno}-${payload.timeLogDetails.first_name} ${payload.timeLogDetails.last_name}`;

      const originalTimeLogDetails = { ...payload.timeLogDetails };
      state.previousTimeLogDetails = originalTimeLogDetails;


      state.selectedTimeLogDetails = payload.timeLogDetails;

      store.dispatch("toggleUpdateTimeLogVisibility", {
        isVisible: true,
        action: payload.action,
      });
    },
    updateSelectedLeaveTransaction(state, leave) {
      console.log(
        "updateSelectedLeaveTransaction mutation",
        leave.leaveDetails,
        leave.action
      );
      leave.leaveDetails.empno = `${leave.leaveDetails.empno}-${leave.leaveDetails.first_name} ${leave.leaveDetails.last_name}`;
     
      const originalLeaveDetails = { ...leave.leaveDetails };
      state.previousLeaveDetails = originalLeaveDetails;

      state.selectedLeaveDetails = leave.leaveDetails;

      store.dispatch("toggleUpdateLeaveTransactionVisibility", {
        isVisible: true,
        action: leave.action,
      });
    },
    updateSelectedExpenses(state, expenses) {
      console.log(
        "updateSelectedExpenses mutation",
        expenses.expensesDetails,
        expenses.action
      );
      expenses.expensesDetails.empno = `${expenses.expensesDetails.empno}-${expenses.expensesDetails.name}`;
      expenses.expensesDetails.time =expenses.expensesDetails.exp_datetime.substring(11,19);
     
      const originalExpensesDetails = { ...expenses.expensesDetails };
      state.previousExpensesDetails = originalExpensesDetails;

      state.selectedExpensesDetails = expenses.expensesDetails;

      store.dispatch("toggleUpdateExpensesVisibility", {
        isVisible: true,
        action: expenses.action,
      });
    },
    updateSelectedExpensesPic(state, expenses) {
      console.log(
        "updateSelectedExpensesPic mutation",
        expenses.expensesPicDetails,
        expenses.action
      );
      // expenses.expensesDetails.empno = `${expenses.expensesDetails.empno}-${expenses.expensesDetails.name}`;
     
      // const originalExpensesDetails = { ...expenses.expensesDetails };
      // state.previousExpensesDetails = originalExpensesDetails;

        // state.selectedExpensesPicDetails = expenses.expensesPicDetails;
        if(expenses.expensesPicDetails !== null) {
          state.selectedExpensesPicDetails = expenses.expensesPicDetails;
        } else {
          state.selectedExpensesPicDetails = [{file_name: "default", picture_path: expensesImage}]
        }
       

      store.dispatch("toggleUpdateExpensesVisibility", {
        isVisible: true,
        action: expenses.action,
      });
    },
  },
  actions: {
    async fetchDepartments({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetDepartmentWeb().then((response) => {
          let departments = [];
          departments = response.data.Dept.map((dept) => {
            // Convert edit_date to a Date object and format it
            const editdate = new Date(dept.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (dept.edit_user !== null) {
              formattedEditDate = `${dept.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(dept.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (dept.create_user !== null) {
              formattedCreateDate = `${dept.create_user} ${formattedCreateDate}`;
            }

            return {
              ...dept,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          commit("setDepartments", departments);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchWorkGroups({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetWorkGroupWithoutCheckDevice().then((response) => {
          let workgroups = [];
          workgroups = response.data.WorkGrp.map((workgroup) => {
            // Convert edit_date to a Date object and format it
            const editdate = new Date(workgroup.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (workgroup.edit_user !== null) {
              formattedEditDate = `${workgroup.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(workgroup.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (workgroup.create_user !== null) {
              formattedCreateDate = `${workgroup.create_user} ${formattedCreateDate}`;
            }

            return {
              ...workgroup,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          commit("setWorkGroups", workgroups);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchRaces({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetRaceWithoutCheckDevice().then((response) => {
          let races = [];
          races = response.data.Race.map((race) => {
            // Convert edit_date to a Date object and format it
            const editdate = new Date(race.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (race.edit_user !== null) {
              formattedEditDate = `${race.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(race.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (race.create_user !== null) {
              formattedCreateDate = `${race.create_user} ${formattedCreateDate}`;
            }

            return {
              ...race,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          commit("setRaces", races);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchBranches({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetBranchWithoutCheckDevice().then((response) => {
          let branches = [];
          branches = response.data.Branch.map((branch) => {
            // Convert edit_date to a Date object and format it
            const editdate = new Date(branch.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (branch.edit_user !== null) {
              formattedEditDate = `${branch.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(branch.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (branch.create_user !== null) {
              formattedCreateDate = `${branch.create_user} ${formattedCreateDate}`;
            }

            return {
              ...branch,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          commit("setBranchess", branches);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchLeaveCodes({ commit }) {
      try {
        commit("setLoading", true);
        console.log("api.GetLeaveCodeWithoutCheckDevice()");
        await api.GetLeaveCodeWithoutCheckDevice().then((response) => {
          let leaves = [];
          leaves = response.data.LeaveCode.map((leave) => {
            const editdate = new Date(leave.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (leave.edit_user !== null) {
              formattedEditDate = `${leave.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(leave.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (leave.create_user !== null) {
              formattedCreateDate = `${leave.create_user} ${formattedCreateDate}`;
            }

            return {
              ...leave,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });
          commit("setLeaveCodes", leaves);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchLeavePolicy({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetLeavePolicyWithoutCheckDevice().then((response) => {
          let leavesPolicys = [];
          leavesPolicys = response.data.LeavePolicy.map((leavesPolicy) => {
            const editdate = new Date(leavesPolicy.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (leavesPolicy.edit_user !== null) {
              formattedEditDate = `${leavesPolicy.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(leavesPolicy.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (leavesPolicy.create_user !== null) {
              formattedCreateDate = `${leavesPolicy.create_user} ${formattedCreateDate}`;
            }

            return {
              ...leavesPolicy,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          console.log("setLeavePolicyList", leavesPolicys);
          commit("setLeavePolicyList", leavesPolicys);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchJobGrades({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetJobGradeWithoutCheckDevice().then((response) => {
          let jobGrades = [];
          jobGrades = response.data.FjobGd.map((jobGrade) => {
            const editdate = new Date(jobGrade.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (jobGrade.edit_user !== null) {
              formattedEditDate = `${jobGrade.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(jobGrade.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (jobGrade.create_user !== null) {
              formattedCreateDate = `${jobGrade.create_user} ${formattedCreateDate}`;
            }

            return {
              ...jobGrade,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          console.log("setJobGradesList", jobGrades);
          commit("setJobGradesList", jobGrades);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchCountries({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetCountryWithoutCheckDevice().then((response) => {
          console.log("GetCountryWithoutCheckDevice:", response.data.Country);
          let countries = [];
          countries = response.data.Country.map((country) => {
            const editdate = new Date(country.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (country.edit_user !== null) {
              formattedEditDate = `${country.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(country.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (country.create_user !== null) {
              formattedCreateDate = `${country.create_user} ${formattedCreateDate}`;
            }

            return {
              ...country,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          console.log("setCountryList", countries);
          commit("setCountryList", countries);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchCostCenters({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetCostCenterWithoutCheckDevice().then((response) => {
          let costCenters = [];
          costCenters = response.data.Fcost.map((costCenter) => {
            const editdate = new Date(costCenter.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (costCenter.edit_user !== null) {
              formattedEditDate = `${costCenter.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(costCenter.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (costCenter.create_user !== null) {
              formattedCreateDate = `${costCenter.create_user} ${formattedCreateDate}`;
            }

            return {
              ...costCenter,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          console.log("setCostCenterList", costCenters);
          commit("setCostCenterList", costCenters);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchNationality({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetNationalityWithoutCheckDeviceId().then((response) => {
          console.log("GetNationalityWithoutCheckDeviceId", response.data);

          let nations = response.data.National.map((nation) => {
            const editdate = new Date(nation.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (nation.edit_user !== null) {
              formattedEditDate = `${nation.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(nation.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (nation.create_user !== null) {
              formattedCreateDate = `${nation.create_user} ${formattedCreateDate}`;
            }

            return {
              ...nation,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          console.log("setNationalityList", nations);
          commit("setNationalityList", nations);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchReligion({ commit }) {
      try {
        commit("setLoading", true);
        await api.GetReligionWithoutCheckDeviceId().then((response) => {
          console.log("GetReligionWithoutCheckDeviceId", response.data);

          let religions = response.data.Religion.map((religion) => {
            const editdate = new Date(religion.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (religion.edit_user !== null) {
              formattedEditDate = `${religion.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(religion.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (religion.create_user !== null) {
              formattedCreateDate = `${religion.create_user} ${formattedCreateDate}`;
            }

            return {
              ...religion,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });

          console.log("setReligionList", religions);
          commit("setReligionList", religions);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchSalarySchemes({ commit }) {
      try {
        commit("setLoading", true);
        console.log("api.GetSalarySchemaWithoutCheckDevice()");
        await api.GetSalarySchemaWithoutCheckDevice().then((response) => {
          let salarySchemes = [];
          salarySchemes = response.data.SalaryScheme.map((salaryScheme) => {
            const editdate = new Date(salaryScheme.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (salaryScheme.edit_user !== null) {
              formattedEditDate = `${salaryScheme.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(salaryScheme.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (salaryScheme.create_user !== null) {
              formattedCreateDate = `${salaryScheme.create_user} ${formattedCreateDate}`;
            }

            return {
              ...salaryScheme,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });
          commit("setSalarySchemes", salarySchemes);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchAddress({ commit }) {
      try {
        commit("setLoading", true);
        console.log("api.GetAddressWithoutCheckDevice()");
        await api.GetAddressWithoutCheckDevice().then((response) => {
          let addresses = [];
          addresses = response.data.Address.map((address) => {
            const editdate = new Date(address.edit_date);
            let formattedEditDate = editdate.toLocaleString();
            if (address.edit_user !== null) {
              formattedEditDate = `${address.edit_user} ${formattedEditDate}`;
            }

            // Convert create_date to a Date object and format it
            const createdate = new Date(address.create_date);
            let formattedCreateDate = createdate.toLocaleString();
            if (address.create_user !== null) {
              formattedCreateDate = `${address.create_user} ${formattedCreateDate}`;
            }

            return {
              ...address,
              edit_date: formattedEditDate,
              create_date: formattedCreateDate,
            };
          });
          commit("setAddress", addresses);
          commit("setLoading", false);
        });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchEmployeeList({ commit }) {
      try {
        commit("setLoading", true);
        await api
          .GetEmployeeWeb({
            empno: "",
          })
          .then((response) => {
            let employees = response.data.Employee;

            employees = employees.map((emp, index) => {
              const passportDate = new Date(emp.passdte);
              const dd = String(passportDate.getDate()).padStart(2, "0");
              const mm = String(passportDate.getMonth() + 1).padStart(2, "0");
              const yyyy = passportDate.getFullYear();
              emp.passdte = `${dd}/${mm}/${yyyy}`;

              const workPermitDate = new Date(emp.permitdte);
              const dd1 = String(workPermitDate.getDate()).padStart(2, "0");
              const mm1 = String(workPermitDate.getMonth() + 1).padStart(
                2,
                "0"
              );
              const yyyy1 = workPermitDate.getFullYear();
              emp.permitdte = `${dd1}/${mm1}/${yyyy1}`;

              const medicalDate = new Date(emp.medical_checkup_date);
              const dd2 = String(medicalDate.getDate()).padStart(2, "0");
              const mm2 = String(medicalDate.getMonth() + 1).padStart(2, "0");
              const yyyy2 = medicalDate.getFullYear();
              emp.medical_checkup_date = `${dd2}/${mm2}/${yyyy2}`;

              if (emp.paymode === "B") {
                emp.paymode = "Bank";
              } else if (emp.paymode === "C") {
                emp.paymode = "Cash";
              } else if (emp.paymode === "Q") {
                emp.paymode = "Cheque";
              }

              return { ...emp, key: index };
            });
            console.log("fetchEmployeeList", employees);
            commit("setEmployeeList", employees);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchEmployeeListWithEmpNo({ commit }, empno) {
      try {
        commit("setLoading", true);
        await api
          .GetEmployeeWeb(empno.empno,
          )
          .then((response) => {
            let employees = response.data.Employee[0];
            console.log("fetchEmployeeList", employees);
            commit("setEmployeeList", employees);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchForeignEmployeeList({ commit }) {
      try {
        commit("setLoading", true);
        await api
          .GetForeignEmployeeWeb({
            empno: "",
          })
          .then((response) => {
            let employees = response.data.Employee;

            employees = employees.map((emp, index) => {
              const passportDate = new Date(emp.passdte);
              const dd = String(passportDate.getDate()).padStart(2, "0");
              const mm = String(passportDate.getMonth() + 1).padStart(2, "0");
              const yyyy = passportDate.getFullYear();
              emp.passdte = `${dd}/${mm}/${yyyy}`;

              const workPermitDate = new Date(emp.permitdte);
              const dd1 = String(workPermitDate.getDate()).padStart(2, "0");
              const mm1 = String(workPermitDate.getMonth() + 1).padStart(
                2,
                "0"
              );
              const yyyy1 = workPermitDate.getFullYear();
              emp.permitdte = `${dd1}/${mm1}/${yyyy1}`;

              const medicalDate = new Date(emp.medical_checkup_date);
              const dd2 = String(medicalDate.getDate()).padStart(2, "0");
              const mm2 = String(medicalDate.getMonth() + 1).padStart(2, "0");
              const yyyy2 = medicalDate.getFullYear();
              emp.medical_checkup_date = `${dd2}/${mm2}/${yyyy2}`;

              if (emp.paymode === "B") {
                emp.paymode = "Bank";
              } else if (emp.paymode === "C") {
                emp.paymode = "Cash";
              } else if (emp.paymode === "Q") {
                emp.paymode = "Cheque";
              }

              return { ...emp, key: index };
            });
            console.log("fetchForeignEmployeeList", employees);
            commit("setForeignEmployeeList", employees);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchLeaveCodeDetails({ commit }, payload) {
      try {
        await api.GetLeaveCodeWithoutCheckDevice().then((response) => {
          const leaveCodeData = response.data.LeaveCode;
          const filteredLeaveCodes = leaveCodeData.filter(
            (code) => code.leave_code === payload.leaveCode
          );

          if (filteredLeaveCodes.length > 0) {
            const leaveCodeDetails = filteredLeaveCodes[0]; // Assuming filteredLeaveCodes contains only one item
            //console.error("leaveCodeDetails", leaveCodeDetails);
            // Commit mutation to update selectedLeaveCode
            commit("updateSelectedLeaveCode", {
              leaveCodeDetails: leaveCodeDetails,
              action: payload.action,
            });
          } else {
            console.error(
              "LeaveCode not found for leaveCode:",
              payload.leaveCode
            );
          }
        });
      } catch (error) {
        console.error("Error fetching LeaveCode details:", error.message);
      }
    },
    async fetchSalarySchemeDetails({ commit }, payload) {
      try {
        await api.GetSalarySchemaWithoutCheckDevice().then((response) => {
          const salarySchemeData = response.data.SalaryScheme;

          const filteredSalarySchemes = salarySchemeData.filter(
            (code) => code.salary_scheme_code === payload.salarySchemeCode
          );

          if (filteredSalarySchemes.length > 0) {
            const salarySchemeDetails = filteredSalarySchemes[0]; // Assuming filteredLeaveCodes contains only one item

            commit("updateSelectedSalaryScheme", {
              salarySchemeDetails: salarySchemeDetails,
              action: payload.action,
            });
          } else {
            console.error(
              "Salary Scheme Code not found for leaveCode:",
              payload.leaveCode
            );
          }
        });
      } catch (error) {
        console.error("Error fetching LeaveCode details:", error.message);
      }
    },
    async fetchAddressDetails({ commit }, payload) {
      try {
        await api.GetAddressWithoutCheckDevice().then((response) => {
          const addressData = response.data.Address;

          const filteredAddress = addressData.filter(
            (code) => code.addno === payload.addno
          );

          if (filteredAddress.length > 0) {
            const addressDetails = filteredAddress[0]; // Assuming filteredLeaveCodes contains only one item

            commit("updateSelectedAddress", {
              addressDetails: addressDetails,
              action: payload.action,
            });
          } else {
            console.error("Address Code not found for addno:", payload.addno);
          }
        });
      } catch (error) {
        console.error("Error fetching LeaveCode details:", error.message);
      }
    },
    async fetchLeavePolicyDetails({ commit }, payload) {
      try {
        await api.GetLeavePolicyWithoutCheckDevice().then((response) => {
          const leavePolicyData = response.data.LeavePolicy;
          const filteredLeavePolicys = leavePolicyData.filter(
            (leavePolicyCode) =>
              leavePolicyCode.leave_policy_code === payload.leavePolicyCode
          );
          if (filteredLeavePolicys.length > 0) {
            const leavePolicyDetails = filteredLeavePolicys[0]; // Assuming filteredLeaveCodes contains only one item
            console.log(
              "response.data.LeavePolicy updateSelectedLeavePolicyCode"
            );
            commit("updateSelectedLeavePolicyCode", {
              leavePolicyDetails: leavePolicyDetails,
              action: payload.action,
            });
          } else {
            console.error(
              "Leave Polcy not found for leave policy code:",
              payload.leave_policy_code
            );
          }
        });
      } catch (error) {
        console.error("Error fetching Leave Policy details", error.message);
      }
    },
    async fetchDepartmentDetails({ commit }, payload) {
      try {
        await api.GetDepartmentWeb().then((response) => {
          const departmentData = response.data.Dept;
          const filteredDepartment = departmentData.filter(
            (department) => department.dept_code === payload.deptCode
          );
          if (filteredDepartment.length > 0) {
            const departmentDetails = filteredDepartment[0]; // Assuming filteredLeaveCodes contains only one item

            commit("updateSelectedDepartment", {
              departmentDetails: departmentDetails,
              action: payload.action,
            });
          } else {
            console.error(
              "Department not found for leave policy code:",
              payload.deptCode
            );
          }
        });
      } catch (error) {
        console.error("Error fetching Department details", error.message);
      }
    },
    async fetchBranchDetails({ commit }, payload) {
      try {
        await api.GetBranchWithoutCheckDevice().then((response) => {
          const branchData = response.data.Branch;
          const filteredBranch = branchData.filter(
            (branch) => branch.branch === payload.branch
          );
          if (filteredBranch.length > 0) {
            const branchDetails = filteredBranch[0]; // Assuming filteredLeaveCodes contains only one item

            const editdate = new Date(filteredBranch[0].edit_date);
            const dd = String(editdate.getDate()).padStart(2, "0");
            const mm = String(editdate.getMonth() + 1).padStart(2, "0"); // January is 0!
            const yyyy = editdate.getFullYear();
            branchDetails.edit_date = `${dd}/${mm}/${yyyy}`;

            const createdate = new Date(filteredBranch[0].create_date);
            const dd1 = String(createdate.getDate()).padStart(2, "0");
            const mm1 = String(createdate.getMonth() + 1).padStart(2, "0"); // January is 0!
            const yyyy1 = createdate.getFullYear();
            branchDetails.create_date = `${dd1}/${mm1}/${yyyy1}`;

            branchDetails.company = filteredBranch[0].company;
            branchDetails.shortName = filteredBranch[0].short_name;
            branchDetails.companyRegNo = filteredBranch[0].brno;
            branchDetails.sstRegister = filteredBranch[0].sst_register;
            branchDetails.merchantId = filteredBranch[0].edi_loc;
            branchDetails.is_hq = filteredBranch[0].is_hq;
            branchDetails.add1 = filteredBranch[0].add1;
            branchDetails.add2 = filteredBranch[0].add2;
            branchDetails.add3 = filteredBranch[0].add3;
            branchDetails.add4 = filteredBranch[0].add4;
            branchDetails.postcode = filteredBranch[0].postcode;
            branchDetails.phone1 = filteredBranch[0].phone_off1;
            branchDetails.phone2 = filteredBranch[0].phone_off2;
            branchDetails.fax = filteredBranch[0].fax;
            branchDetails.ptc = filteredBranch[0].pic;
            branchDetails.email = filteredBranch[0].e_mail;
            branchDetails.url = filteredBranch[0].url;
            branchDetails.bussinessInfo = filteredBranch[0].business_info;
            branchDetails.logoFilename = filteredBranch[0].comp_logo_filename;
            branchDetails.logoFilename = filteredBranch[0].comp_logo_filename;
            branchDetails.comp_logo_path = filteredBranch[0].comp_logo_path;
            branchDetails.comp_photo_path = filteredBranch[0].comp_photo_path;
            branchDetails.photoFilename = filteredBranch[0].comp_photo_filename;

            commit("updateSelectedBranch", {
              branchDetails: branchDetails,
              action: payload.action,
            });
          } else {
            console.error(
              "Request failed:",
              response.status,
              response.statusText
            );
          }
        });
      } catch (error) {
        console.error("Error fetching branch details", error.message);
      }
    },
    async fetchNationalityDetails({ commit }, payload) {
      try {
        await api.GetNationalityWithoutCheckDeviceId().then((response) => {
          const nationalityData = response.data.National;
          const filteredNationalities = nationalityData.filter(
            (nation) => nation.nation === payload.nation
          );
          if (filteredNationalities.length > 0) {
            const nationalityDetails = filteredNationalities[0]; // Assuming filteredLeaveCodes contains only one item

            commit("updateSelectedNationality", {
              nationalityDetails: nationalityDetails,
              action: payload.action,
            });
          } else {
            console.error("Nationality not found for nation:", payload.nation);
          }
        });
      } catch (error) {
        console.error("Error fetching Leave Policy details", error.message);
      }
    },
    async fetchReligionDetails({ commit }, payload) {
      try {
        await api.GetReligionWithoutCheckDeviceId().then((response) => {
          const religionData = response.data.Religion;
          const filteredReligions = religionData.filter(
            (religion) => religion.religion === payload.religion
          );
          if (filteredReligions.length > 0) {
            const religionDetails = filteredReligions[0];

            commit("updateSelectedReligion", {
              religionDetails: religionDetails,
              action: payload.action,
            });
          } else {
            console.error("Nationality not found for nation:", payload.nation);
          }
        });
      } catch (error) {
        console.error("Error fetching Leave Policy details", error.message);
      }
    },
    async fetchJobGradeDetails({ commit }, payload) {
      try {
        await api.GetJobGradeWithoutCheckDevice().then((response) => {
          const jobGradeData = response.data.FjobGd;
          const filteredJobGrades = jobGradeData.filter(
            (jobGrade) => jobGrade.jobgd_code === payload.code
          );
          if (filteredJobGrades.length > 0) {
            const jobGradeDetails = filteredJobGrades[0];

            commit("updateSelectedJobGrade", {
              jobGradeDetails: jobGradeDetails,
              action: payload.action,
            });
          } else {
            console.error("Job Grade not found for nation:", payload.code);
          }
        });
      } catch (error) {
        console.error("Error fetching Job Grade details", error.message);
      }
    },
    async fetchCountryDetails({ commit }, payload) {
      try {
        await api.GetCountryWithoutCheckDevice().then((response) => {
          const countryData = response.data.Country;
          const filteredCountries = countryData.filter(
            (country) => country.country === payload.code
          );
          if (filteredCountries.length > 0) {
            const countryDetails = filteredCountries[0];

            commit("updateSelectedCountry", {
              countryDetails: countryDetails,
              action: payload.action,
            });
          } else {
            console.error("Country not found for nation:", payload.code);
          }
        });
      } catch (error) {
        console.error("Error fetching Country details", error.message);
      }
    },
    async fetchCostCenterDetails({ commit }, payload) {
      try {
        await api.GetCostCenterWithoutCheckDevice().then((response) => {
          const costCenterData = response.data.Fcost;
          const filteredCostCenters = costCenterData.filter(
            (costCenter) => costCenter.costc_code === payload.code
          );
          if (filteredCostCenters.length > 0) {
            const costCenterDetails = filteredCostCenters[0];

            commit("updateSelectedCostCenter", {
              costCenterDetails: costCenterDetails,
              action: payload.action,
            });
          } else {
            console.error("Job Grade not found for nation:", payload.nation);
          }
        });
      } catch (error) {
        console.error("Error fetching Job Grade details", error.message);
      }
    },
    async fetchEmployeeLeaveHistory({ commit }) {
      try {
        //commit("setLoading", true);
        await api
          .GetLeaveHistory({
            empno: "0001",
          })
          .then((response) => {
            console.log("historyData", 3);
            let leaves = response.data.LeaveHistory;
            leaves = leaves.map((leave, index) => {
              const formattedAsAtDate = leave.as_at_date.split("T")[0];
              const formattedEffectiveDateFrom =
                leave.effective_date_from.split("T")[0];
              const formattedEffectiveDateTo =
                leave.effective_date_to.split("T")[0];

              return {
                ...leave,
                asat_date: formattedAsAtDate,
                effectivedate_from: formattedEffectiveDateFrom,
                effectivedate_to: formattedEffectiveDateTo,
                key: index,
              };
            });

            commit("setLeaveHistory", leaves);
            //commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchLeaveHistoryDetails({ commit }, payload) {
      console.log("fetchLeaveHistoryDetails", payload.data, payload.action);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      commit("updateSelectedLeaveHistory", {
        leaveHistoryDetails: payload.data,
        action: payload.action,
      });
    },
    async fetchRaceDetails({ commit }, payload) {
      try {
        await api.GetRaceWithoutCheckDevice().then((response) => {
          const raceData = response.data.Race;
          const filteredRace = raceData.filter(
            (race) => race.race_code === payload.raceCode
          );
          if (filteredRace.length > 0) {
            const raceDetails = filteredRace[0]; // Assuming filteredLeaveCodes contains only one item

            commit("updateSelectedRace", {
              raceDetails: raceDetails,
              action: payload.action,
            });
          } else {
            console.error(
              "Race not found for leave policy code:",
              payload.raceCode
            );
          }
        });
      } catch (error) {
        console.error("Error fetching race details", error.message);
      }
    },
    async fetchCompanyList({ commit }) { //lxy
      try {
        commit("setLoading", true);
        await api
          .GetUserRegisteredMerchant(localStorage.getItem('userId'),
          )
          .then((response) => {
            let merchantProfile = response.data.MerchantProfile;

            if(localStorage.getItem('merchant_no') === "") {
              localStorage.setItem('merchant_no', merchantProfile[0].merchant_no)
            }
            
            localStorage.getItem('merchant_no')
            console.log(localStorage.getItem('merchant_no'))
            console.log("fetchMerchantList", merchantProfile);
            commit("setMerchantNo", merchantProfile[0].merchant_no);
            commit("setMerchantList", merchantProfile);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchCompanyListDetails({ commit }) { //lxy
      try {
        commit("setLoading", true);
        await api
          .GetUserRegisteredMerchant(localStorage.getItem('userId'), localStorage.getItem('merchant_no'),
          )
          .then((response) => {
            let merchantProfile = response.data.MerchantProfile;

            // if(localStorage.getItem('merchant_no') === "") {
            //   localStorage.setItem('merchant_no', merchantProfile[0].merchant_no)
            // }
            
            // localStorage.getItem('merchant_no')
            // console.log(localStorage.getItem('merchant_no'))
            // console.log("fetchMerchantList", merchantProfile);
            // commit("setMerchantNo", merchantProfile[0].merchant_no);
            commit("setCompanyList", merchantProfile);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchTimeLogList({ commit }, timeLog) { //lxy
      try {
        commit("setLoading", true);
        await api
          .GetTimeLogWithoutCheckDevice(
            timeLog.bgnDate,
            timeLog.endDate
          )
          .then((response) => {
            console.log(response.data)
            // let timeLog = response.data.TimeLog;
            let timeLog = response.data !== null ? response.data.TimeLog : null;
            console.log(timeLog)
            if(timeLog !== null) {
              timeLog.forEach(tL => {
                if (tL.clock_date !== null) {
                  var yearMonth = tL.clock_date.substring(0, 10);
                  tL.clock_date = yearMonth;
                }
                tL.closed = tL.closed === 'true' ? 'Closed' : '';
                tL.in_lock = tL.in_lock === 'true' ? 'Locked' : '';
                tL.name = `${tL.first_name} ${tL.last_name}`;
              });
            }
            console.log("updateSelectedTimeLog", timeLog);
            commit("setTimeLogList", timeLog);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchTimeLogDetails({ commit, state }, payload) { //lxy
      try {
        await api.GetTimeLogWithoutCheckDevice(
          payload.clock_date,
          payload.clock_date,
          payload.ID
        ).then((response) => {
          const timeLogData = response.data.TimeLog;

          // if (state.selectedTimeLogDetails) {
          //   state.selectedTimeLogDetails =timeLogData[0]
          //   console.log(state.selectedTimeLogDetails)
          //   commit('savePreviousTimeLogDetails', state.selectedTimeLogDetails);
          // }
          if (state.previousTimeLogDetails) {
            state.selectedTimeLogDetails = { ...state.previousTimeLogDetails };
          }

          if (timeLogData.length > 0) {
            const timeLogDetails = timeLogData[0]; // Assuming filteredLeaveCodes contains only one item

            commit("updateSelectedTimeLog", {
              timeLogDetails: timeLogDetails,
              action: payload.action,
            });
          } else {
            console.error(
              "TimeLog not found",
              payload.ID
            );
          }
        });
      } catch (error) {
        console.error("Error fetching TimeLog details:", error.message);
      }
    },
    async fetchTimeSheetList({ commit }, timeSht) { //lxy
      try {
        commit("setLoading", true);
        await api
          .GetTimeSheet(
            timeSht.dateFrom,
            timeSht.dateTo,
            timeSht.close,
            timeSht.suppressZeroOTHours,
            timeSht.deptCodeFrom,
            timeSht.deptCodeTo,
            timeSht.empnoFrom,
            timeSht.empnoTo,
            timeSht.dailyStatus,
            timeSht.recordStatus
          )
          .then((response) => {
            let timeSheet = response.data !== null ? response.data.TimeSht : null;
            if(timeSheet !== null) {
              timeSheet.forEach(tS => {
                tS.date = tS.date.substring(0, 10)
                tS.name = `${tS.first_name} ${tS.last_name}`
                tS.r24_1 = tS.r24_1 === null ? '-' : tS.r24_1
                tS.r24_2 = tS.r24_2 === null ? '-' : tS.r24_2
                tS.r24_3 = tS.r24_3 === null ? '-' : tS.r24_3
                tS.r24_4 = tS.r24_4 === null ? '-' : tS.r24_4
              });
            }
            console.log("updateSelectedTimeSheet", timeSheet);
            commit("setTimeSheetList", timeSheet);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchLeaveList({ commit }, leave) { //lxy
      try {
        commit("setLoading", true);
        await api
          .GetEmpLeaveHis(
            leave.dateFrom,
            leave.dateTo,
            leave.empno,
            leave.lCode,
            leave.logNo,
            leave.status,
            leave.halfDaySession,
            leave.dept
          )
          .then((response) => {
            let leaveHis = response.data !== null ? response.data.LevHis : null;
            if(leaveHis !== null) {
              leaveHis.forEach(leave => {
                leave.date = leave.date.substring(0, 10)
                leave.name = `${leave.first_name} ${leave.last_name}`
                leave.apprvDateBy = `${leave.approve_by_name ?? ''} ${leave.approve_datetime ? leave.approve_datetime.substring(0, 10) : ' '}`
                leave.rejectDateBy = `${leave.reject_by_name ?? ''} ${leave.reject_datetime ? leave.reject_datetime.substring(0, 10) : ' '}`
                leave.createDateBy = `${leave.create_user ?? ''} ${leave.create_date ? leave.create_date.substring(0, 10) : ' '}`
                leave.editDateBy = `${leave.edit_user ?? ''} ${leave.edit_date ? leave.edit_date.substring(0, 10) : ' '}`
              });
            }
            console.log("updateSelectedLeave", leaveHis);
            commit("setLeaveList", leaveHis);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
      async fetchLeaveDetails({ commit, state }, leave) { //lxy
      try {
        await api.GetEmpLeaveHis(
          leave.dateFrom,
          leave.dateTo,
          leave.empno,
          leave.lCode,
          leave.logNo,
          leave.status,
          leave.halfDaySession,
          leave.dept
        ).then((response) => {
          const leaveData = response.data.LevHis;

          if (state.previousLeaveDetails) {
            state.selectedLeaveDetails = { ...state.previousLeaveDetails };
          }

          if (leaveData.length > 0) {
            const leaveDetails = leaveData[0]; // Assuming filteredLeaveCodes contains only one item

            commit("updateSelectedLeaveTransaction", {
              leaveDetails: leaveDetails,
              action: leave.action,
            });
          } else {
            console.error(
              "Leave not found",
              leave.ID
            );
          }
        });
      } catch (error) {
        console.error("Error fetching LeaveTransaction details:", error.message);
      }
    },
    async fetchEndMonthList({ commit }, endMonth) { //lxy
      try {
        commit("setLoading", true);
        await api
          .GetEndJourList(
            endMonth.dateFrom,
            endMonth.dateTo,
            endMonth.empNo,
            endMonth.status,
            endMonth.deptCode
          )
          .then((response) => {
            console.log(response.data)
            // let timeLog = response.data.TimeLog;
            let endMonth = response.data !== null ? response.data.EndJour : null;
            console.log(endMonth)
            if(endMonth !== null) {
              endMonth.forEach(endM => {
                endM.name = `${endM.first_name}`
                endM.work_d = endM.work_d === '0' ? '' : endM.work_d 
                endM.off_d = endM.off_d === '0' ? '' : endM.off_d 
                endM.rest_d = endM.rest_d === '0' ? '' : endM.rest_d 
                endM.holiday_d = endM.holiday_d === '0' ? '' : endM.holiday_d 
                endM.gazetted_d = endM.gazetted_d === '0' ? '' : endM.gazetted_d 
                endM.absent_d = endM.absent_d === '0' ? '' : endM.absent_d 
                endM.ot_work_h = endM.ot_work_h === '0:00' ? '' : endM.ot_work_h 
                endM.ot_rest_h = endM.ot_rest_h === '0:00' ? '' : endM.ot_rest_h 
                endM.ot_holiday_h = endM.ot_holiday_h === '0:00' ? '' : endM.ot_holiday_h 
                endM.ot_gazetted_h = endM.ot_gazetted_h === '0:00' ? '' : endM.ot_gazetted_h 
              });
            }
            console.log("updateSelectedEndMonth", endMonth);
            commit("setEndMonthList", endMonth);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchExpensesList({ commit, state }, expenses) { //lxy
      try {
        commit("setLoading", true);
        await api
          .GetExpenses(
            expenses.expId,
            expenses.expStartDateString,
            expenses.expEndDateString
          )
          .then((response) => {
            console.log(response.data)
            // let timeLog = response.data.TimeLog;
            let expenses = response.data !== null ? response.data.Exp : null;
            console.log(expenses)
            if(expenses !== null) {
              state.expensesTotalAmount = parseFloat(0).toFixed(2)
              expenses.forEach(expenses => {
                expenses.exp_datetime = expenses.exp_datetime.substring(0,10) 
                expenses.mileage = expenses.mileage === '0' ? "" : expenses.mileage 
                expenses.create_date = expenses.create_date.substring(0, 10) + ' ' + expenses.create_date.substring(11, 19)
                expenses.edit_date = expenses.edit_date.substring(0, 10) + ' ' + expenses.edit_date.substring(11, 19)
                if (expenses.amount !== '0') {
                  state.expensesTotalAmount = parseFloat(expenses.amount) + parseFloat(state.expensesTotalAmount)
                  expenses.amount = parseFloat(expenses.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
              
              });
              state.expensesTotalAmount = state.expensesTotalAmount.toFixed(2)
                state.expensesTotalAmount = parseFloat(state.expensesTotalAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            } else {
              state.expensesTotalAmount = parseFloat(0).toFixed(2)
            }
            console.log("updateSelectedExpenses", expenses);
            console.log("updateTotal", state.expensesTotalAmount);
            commit("setExpensesList", expenses);
            commit("setExpensesTotalAmount", state.expensesTotalAmount)
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchExpensesType({ commit }) { //lxy
      try {
        commit("setLoading", true);
        await api
          .GetExpType()
          .then((response) => {
            console.log(response.data)
            let expensesType = response.data !== null ? response.data.ExpType : null;
            console.log(expensesType)
            console.log("updateSelectedExpensesType", expensesType);
            commit("setExpensesTypeList", expensesType);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchExpensesDetails({ commit, state }, expenses) { //lxy
      try {
        await api.GetExpenses(
          expenses.expId
        ).then((response) => {
          const expensesData = response.data.Exp;

          if (state.previousExpensesDetails) {
            state.selectedExpensesDetails = { ...state.previousExpensesDetails };
          }

          if (expensesData.length > 0) {
            const expensesDetails = expensesData[0]; // Assuming filteredLeaveCodes contains only one item

            commit("updateSelectedExpenses", {
              expensesDetails: expensesDetails,
              action: expenses.action,
            });
            
          } else {
            console.error(
              "Expenses not found",
              expenses.expId
            );
          }
        });
      } catch (error) {
        console.error("Error fetching Expenses details:", error.message);
      }
    },
    async fetchExpensesPicDetails({ commit }, expenses) { //lxy
      console.log(expenses.expId)

      try {
        await api.GetExpPicture(
          expenses.expId
        ).then((response) => {
          console.log(response.data)
          let expensesPicDetails = response.data !== null ? response.data.ExpFileAttach : null;

            if (response.data !== null && expensesPicDetails.length > 0) {
              console.log("lengthData", expensesPicDetails)
  
              commit("updateSelectedExpensesPic", {
                expensesPicDetails: expensesPicDetails,
                action: expenses.action,
              });

            } else {
              console.error(
                "Expenses Picture not found",
                expenses.expId
              );

              commit("updateSelectedExpensesPic", {
                expensesPicDetails: expensesPicDetails,
                action: expenses.action,
              });
            }
          
        });
      } catch (error) {
        console.error("Error fetching Expenses Picture details:", error.message);
      }
    },
    async fetchExpensesPic({ commit }, expenses) { //lxy
      console.log(expenses.expId)

      try {
        await api.GetExpPicture(
          expenses.expId
        ).then((response) => {
          console.log(response.data)
          let expensesPicDetails = response.data !== null ? response.data.ExpFileAttach : null;
  
            if (response.data !== null && expensesPicDetails.length > 0) {
              console.log("lengthData", expensesPicDetails)
  
            commit("setExpensesPicList", expensesPicDetails);

            } else {
              console.error(
                "Expenses Picture not found",
                expenses.expId
              );
            }
          
        });
      } catch (error) {
        console.error("Error fetching Expenses Picture details:", error.message);
      }
    },
    async fetchLogin({ commit }, login) { //lxy
      try {
        commit("setLoading", true);
        await api
          .MyWorkAdminLoginResetPwdV2(login.userPhone, login.userPwd)
          .then((response) => {
            console.log(response.data)
            let loginRes = response.data !== null ? response.data.Table1 : null;
            console.log("Login", login);
            localStorage.setItem('sessionId', loginRes[0].sessionId)
            localStorage.setItem('userId', loginRes[0].userId)
            localStorage.setItem('userPhone', login.userPhone)
            console.log(localStorage.getItem('userId'), localStorage.getItem('sessionId'), localStorage.getItem('userPhone'));
            commit("setLogin", loginRes);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    async fetchPrintReport({ commit }, report) { //lxy
      console.log(report)
      try {
        commit("setLoading", true);
        await api
          .PrintReport(
            report.expStartDateString,
            report.expEndDateString,
            report.expId,
            report.type,
            report.empno,
            report.dept,
            report.branch,
            report.doc,
          )
          .then((response) => {
            console.log(response.data)
            let printReport = response.data !== null ? response.data.PDF : null;
           
            console.log(printReport)
            
            console.log("updateSelectedReport", printReport);
            commit("setPrintReport", printReport);
            commit("setLoading", false);
          });
      } catch (error) {
        // Handle errors
        commit("setLoading", false);
      }
    },
    toggleAddDepartmentVisibility({ commit }, isVisible) {
      commit("toggleAddDepartmentVisibility", isVisible);
    },
    toggleAddBranchVisibility({ commit }, isVisible) {
      commit("toggleAddBranchVisibility", isVisible);
    },
    toggleAddRaceVisibility({ commit }, isVisible) {
      commit("toggleAddRaceVisibility", isVisible);
    },
    toggleAddCountryVisibility({ commit }, isVisible) {
      commit("toggleAddCountryVisibility", isVisible);
    },
    toggleAddJobGradeVisibility({ commit }, isVisible) {
      commit("toggleAddJobGradeVisibility", isVisible);
    },
    toggleAddLeaveCodeVisibility({ commit }, isVisible) {
      console.log("toggleAddLeaveCodeVisibility", isVisible);
      commit("toggleAddLeaveCodeVisibility", isVisible);
    },
    toggleAddSalarySchemeVisibility({ commit }, isVisible) {
      console.log("toggleAddSalarySchemeVisibility", isVisible);
      commit("toggleAddSalarySchemeVisibility", isVisible);
    },
    toggleAddAddressVisibility({ commit }, isVisible) {
      console.log("toggleAddAddressVisibility", isVisible);
      commit("toggleAddAddressVisibility", isVisible);
    },
    toggleUpdateLeaveCodeVisibility({ commit }, payload) {
      commit("toggleUpdateLeaveCodeVisibility", payload);
    },
    toggleUpdateSalarySchemeVisibility({ commit }, payload) {
      commit("toggleUpdateSalarySchemeVisibility", payload);
    },
    toggleUpdateCountryVisibility({ commit }, payload) {
      commit("toggleUpdateCountryVisibility", payload);
    },
    toggleUpdateAddressVisibility({ commit }, payload) {
      commit("toggleUpdateAddressVisibility", payload);
    },
    toggleUpdateDepartmentVisibility({ commit }, payload) {
      commit("toggleUpdateDepartmentVisibility", payload);
    },
    toggleUpdateJobGradeVisibility({ commit }, payload) {
      commit("toggleUpdateJobGradeVisibility", payload);
    },
    toggleUpdateBranchVisibility({ commit }, payload) {
      commit("toggleUpdateBranchVisibility", payload);
    },
    toggleUpdateRaceVisibility({ commit }, payload) {
      commit("toggleUpdateRaceVisibility", payload);
    },
    toggleAddLeavePolicyVisibility({ commit }, isVisible) {
      commit("toggleAddLeavePolicyVisibility", isVisible);
    },
    toggleAddNationalityVisibility({ commit }, isVisible) {
      commit("toggleAddNationalityVisibility", isVisible);
    },
    toggleAddReligionVisibility({ commit }, isVisible) {
      commit("toggleAddReligionVisibility", isVisible);
    },
    toggleAddCostCenterVisibility({ commit }, isVisible) {
      commit("toggleAddCostCenterVisibility", isVisible);
    },
    toggleAddTimeLogVisibility({ commit }, isVisible) {
      commit("toggleAddTimeLogVisibility", isVisible);
    },
    toggleAddLeaveTransactionVisibility({ commit }, isVisible) { //lxy
      commit("toggleAddLeaveTransactionVisibility", isVisible);
    },
    toggleAddExpensesVisibility({ commit }, isVisible) { //lxy
      commit("toggleAddExpensesVisibility", isVisible);
    },
    toggleUpdateLeavePolicyVisibility({ commit }, payload) {
      commit("toggleUpdateLeavePolicyVisibility", payload);
    },
    toggleUpdateCostCenterVisibility({ commit }, payload) {
      commit("toggleUpdateCostCenterVisibility", payload);
    },
    toggleUpdateNationalityVisibility({ commit }, payload) {
      commit("toggleUpdateNationalityVisibility", payload);
    },
    toggleUpdateReligionVisibility({ commit }, payload) {
      commit("toggleUpdateReligionVisibility", payload);
    },
    updateLeaveCodeEditRecord({ commit }, editRecordVal) {
      commit("updateLeaveCodeEditRecord", editRecordVal);
    },
    updateLeavePolicyEditRecord({ commit }, editRecordVal) {
      console.log("updateLeavePolicyEditRecord", editRecordVal);
      commit("updateLeavePolicyEditRecord", editRecordVal);
    },
    selectLeaveCode({ commit }, leaveCode) {
      console.log("selectLeaveCode", leaveCode);
      commit("selectLeaveCode", leaveCode);
    },
    toggleUpdateLeaveHistoryVisibility({ commit }, payload) {
      commit("toggleUpdateLeaveHistoryVisibility", payload);
    },
    toggleUpdateTimeLogVisibility({ commit }, payload) {
      commit("toggleUpdateTimeLogVisibility", payload);
    },
    toggleUpdateLeaveTransactionVisibility({ commit }, payload) {
      commit("toggleUpdateLeaveTransactionVisibility", payload);
    },
    toggleUpdateExpensesVisibility({ commit }, payload) {
      commit("toggleUpdateExpensesVisibility", payload);
    },
  },
});

export default store;
