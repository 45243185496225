import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "LoginScreen",
      component: () => import("./views/user/loginScreen.vue"),
    },
    {
      path: "/companyList",
      name: "CompanyList",
      component: () => import("./views/mywork/masterFile/companyList.vue"),
    },
    {
      path: "/timeLogList",
      name: "TimeLogList",
      component: () => import("./views/mywork/masterFile/timeLog.vue"),
    },
    {
      path: "/timeSheetList",
      name: "TimeSheetList",
      component: () => import("./views/mywork/masterFile/timeSheet.vue"),
    },
    {
      path: "/leaveTransactionList",
      name: "LeaveTransactionList",
      component: () => import("./views/mywork/masterFile/leaveTransaction.vue"),
    },
    {
      path: "/endMonthList",
      name: "EndMonthList",
      component: () => import("./views/mywork/masterFile/endMonth.vue"),
    },
    {
      path: "/expensesList",
      name: "ExpensesList",
      component: () => import("./views/mywork/masterFile/expensesEntry.vue"),
    },
    {
      path: "/timeShtClose",
      name: "TimeSheetClose",
      component: () => import("./views/mywork/masterFile/timeShtClose.vue"),
    },
    {
      path: "/employeeList",
      name: "EmployeeList",
      component: () => import("./views/mywork/masterFile/employeeList.vue"),
    },

    {
      path: "/empDefaultSetup",
      name: "EmpDefaultSetup",
      component: () => import("./views/mywork/masterFile/empDefaultSetup.vue"),
    },
    {
      path: "/leaveSetup",
      name: "LeaveSetup",
      component: () => import("./views/mywork/masterFile/leaveSetup.vue"),
    },
    {
      path: "/dashboardLayout",
      name: "DashboardLayout",
      component: () => import("./views/mywork/masterFile/dashboardLayout.vue"),
    },
    {
      path: "/foreignerWorkerSetting",
      name: "ForeignerWorkerSetting",
      component: () =>
        import("./views/mywork/masterFile/foreignerWorkerSetting.vue"),
    },
    {
      path: "/salarySchemeBankStatutorySetting",
      name: "SalarySchemeBankStatutorySetting",
      component: () =>
        import(
          "./views/mywork/masterFile/salarySchemeBankStatutorySetting.vue"
        ),
    },
    {
      path: "/humanResourceSetting",
      name: "HumanResourceSetting",
      component: () =>
        import("./views/mywork/masterFile/humanResourceSetting.vue"),
    },
    {
      path: "/generalSetting",
      name: "GeneralSetting",
      component: () => import("./views/mywork/masterFile/generalSetting.vue"),
    },
    {
      path: "/companyList",
      name: "companyList",
      component: () => import("./views/companySelect/companyList.vue"),
    },

    // {
    //   path: "/branchList",
    //   name: "BranchList",
    //   component: () => import("./views/mywork/masterFile/branchList.vue"),
    // },
    // {
    //   path: "/addBranch",
    //   name: "AddBranch",
    //   component: () => import("./views/mywork/masterFile/addBranch.vue"),
    // },
    // {
    //   path: "/updateBranch/:id/:action",
    //   name: "UpdateBranch",
    //   component: () => import("./views/mywork/masterFile/updateBranch.vue"),
    // },

    // {
    //   path: "/raceList",
    //   name: "RaceList",
    //   component: () => import("./views/mywork/masterFile/raceList.vue"),
    // },
    // {
    //   path: "/addRace",
    //   name: "AddRace",
    //   component: () => import("./views/mywork/masterFile/addRace.vue"),
    // },
    // {
    //   path: "/updateRace/:id/:action",
    //   name: "UpdateRace",
    //   component: () => import("./views/mywork/masterFile/updateRace.vue"),
    // },

    // {
    //   path: "/departmentList",
    //   name: "departmentList",
    //   component: () => import("./views/mywork/masterFile/departmentList.vue"),
    // },
    // {
    //   path: "/addDepartment",
    //   name: "AddDepartment",
    //   component: () => import("./views/mywork/masterFile/addDepartment.vue"),
    // },
    // {
    //   path: "/updateDepartment/:id/:action",
    //   name: "UpdateDepartment",
    //   component: () => import("./views/mywork/masterFile/updateDepartment.vue"),
    // },
  ],
});
export default router;
