import axios from "axios";
// import store from "store";
const mainParams = {
  wsCodeCrypt: "TBSMYWORK",
  // caUid: "mywork_devp_3",
  caUid: "mywork_uat",
  caPwd: "123456",
  appId: "MyWork.Admin.App",
  deviceId: "",
  merchantNo: localStorage.getItem('merchant_no'),
  mLoginId: localStorage.getItem('userPhone'),
};
const axiosInstance = axios.create({
  // baseURL: "http://localhost:53131/MainService.svc/webapi/",
  baseURL: 'https://tbswincloud1.tbsdns.com/MyWork.MainService/6_1/MainService.svc/webapi',
  // baseURL:
  // "https://tbsweb.tbsdns.com/MyWork.MainService/DEVP/6_1/MainService.svc/webapi/",
});

// Define endpoint methods
const api = {
  // ------------------------- GET Methods -------------------------

  GetDepartmentWeb: () => {
    const requestData = { ...mainParams };
    console.log("GetDepartmentWeb");
    return axiosInstance.get("/GetDepartmentWeb", {
      params: requestData,
    });
  },
  MyWorkAdminLoginResetPwdV2: (userPhone, userPwd) => { //lxy
    console.log("MyWorkAdminLoginResetPwdV2");
    const requestData = { 
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appCode: 'TBSMYWORK',
      merchantNo: 'TBSMYWORK',
      appId: 'MyWork.Admin.App',
      userPhone: userPhone,
      userPwd: userPwd,
      ipAddress: "0.0.0.0",
      latitude: "999",
      longitude: "999",
      deviceId: "",
      appVersion: "1.0.0",
      deviceRemark: "",
      phDeviceId: "",
      phLine1Number: "",
      phNetOpName: "",
      phPhoneType: "",
      phSimSerialNo: "",
      bdBoard: "",
      bdBrand: "",
      bdDevice: "",
      dDisplay: "",
      bdManufacturer: "",
      bdModel: "",
      bdProduct: "",
      pfDeviceId: "",
      regId: "", };
      localStorage.setItem('userPhone', userPhone)
      mainParams.userPhone = localStorage.getItem('userPhone')
      console.log(mainParams.userPhone)

    return axiosInstance.get("/MyWorkAdminLoginResetPwdV2", {
      params: requestData,
    });
  },
  GetUserRegisteredMerchant: (userId, merchant) => { //lxy
    console.log("GetUserRegisteredMerchant");
    const requestData = {
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      merchantNo: merchant,
      appCode: 'TBSMYWORK',
      appId: 'MyWork.Admin.App',
      userId: userId
    };
    return axiosInstance.get("/GetUserRegisteredMerchant", {
      params: requestData,
    });
  },
  IsSessionActive: () => { //lxy
    console.log("IsSessionActive");
    const requestData = {
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      merchantNo: localStorage.getItem('merchant_no'),
      userId: localStorage.getItem('userId'),
      sessionId: localStorage.getItem('sessionId'),
      isLogOut: 'true'
    };
    return axiosInstance.get("/IsSessionActive", {
      params: requestData,
    });
  },
  GetTimeLogWithoutCheckDevice: (bgnDate, endDate, id) => { //lxy
    console.log("GetTimeLogWithoutCheckDevice");
    const requestData = { 
      ...mainParams,
      isTimeHistory: 'false',
      cardNo: '',
      bgnDate: bgnDate,
      endDate: endDate,
      empNoFrom: '',
      empNoTo: '',
      deptNoFrom: '',
      deptNoTo: '',
      branchFrom: '',
      branchTo: '',
      id: id };

    return axiosInstance.get("/GetTimeLogWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetTimeSheet: (bgnDate, endDate, isClose, zeroOt, deptFrom, deptTo, empnoFrom, empnoTo, dailyS, recordS) => { //lxy
    console.log("GetTimeSheet");
    const requestData = { 
      ...mainParams,
      close: isClose,
      dateFrom: bgnDate,
      dateTo: endDate,
      deptCodeFrom: deptFrom,
      deptCodeTo: deptTo,
      empnoFrom: empnoFrom,
      empnoTo: empnoTo,
      dailyStatus: dailyS,
      recordStatus: recordS,
      suppressZeroOTHours: zeroOt };
    return axiosInstance.get("/GetTimeSheet", {
      params: requestData,
    });
  },
  GetEmpLeaveHis: (bgnDate, endDate, empno, lCode, logNo, status, halfDay, dept) => { //lxy
    console.log("GetEmpLeaveHis");
    const requestData = { 
      ...mainParams,
      empno: empno,
      lCode: lCode,
      dateFrom: bgnDate,
      dateTo: endDate,
      logNo: logNo,
      status: status,
      halfDaySession: halfDay,
      dept: dept
      };
    return axiosInstance.get("/GetEmpLeaveHisWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetEndJourList: (bgnDate, endDate, empno, status, dept) => { //lxy
    console.log("GetEndJourList");
    const requestData = { 
      ...mainParams,
      empNo: empno,
      dateFrom: bgnDate,
      dateTo: endDate,
      status: status,
      deptCode: dept
      };
    return axiosInstance.get("/GetEndJourListWeb", {
      params: requestData,
    });
  },
  GetExpenses: (expId, bgnDate, endDate) => { //lxy
    console.log("GetExpenses");
    const requestData = { 
      ...mainParams,
      expId: expId,
      type: "",
      expStartDateString: bgnDate,
      expEndDateString: endDate,
      empno: "",
      dept: "",
      branch: ""
      };
    return axiosInstance.get("/GetExpWithoutNoRecord", {
      params: requestData,
    });
  },
  GetExpType: () => { //lxy
    console.log("GetExpType");
    const requestData = { 
      ...mainParams,
      expType: "",
      expTypeDesc: "",
      startIndex: "0",
      noOfRecords: "100"
      };
    return axiosInstance.get("/GetExpType", {
      params: requestData,
    });
  },
  GetExpPicture: (expId) => { //lxy
    console.log("GetExpPicture");
    const requestData = { 
      ...mainParams,
      expId: expId,
      bgnLimit: "0",
      endLimit: "0"
      };
    return axiosInstance.get("/GetExpPicture", {
      params: requestData,
    });
  },
  PrintReport: (dateFrom, dateTo ) => { //lxy
    console.log("PrintReport");
    const requestData = { 
      ...mainParams,
      expStartDateString: dateFrom,
      expEndDateString: dateTo,
      expId: "",
      type: "",
      empno: "",
      dept: "",
      branch: "",
      doc: "EXP"
      };
    return axiosInstance.get("/PrintReport", {
      params: requestData,
    });
  },
  GetRaceWithoutCheckDevice: () => {
    const requestData = { ...mainParams };
    console.log("GetRaceWithoutCheckDevice");
    return axiosInstance.get("/GetRaceWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetCostCenterWithoutCheckDevice: () => {
    const requestData = { ...mainParams };
    console.log("GetCostCenterWithoutCheckDevice");
    return axiosInstance.get("/GetCostCenterWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetBranchWithoutCheckDevice: () => {
    const requestData = { ...mainParams };
    console.log("GetBranchWithoutCheckDevice");
    return axiosInstance.get("/GetBranchWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetNationalityWithoutCheckDeviceId: () => {
    const requestData = { ...mainParams };
    console.log("GetNationalityWithoutCheckDeviceId");
    return axiosInstance.get("/GetNationalityWithoutCheckDeviceId", {
      params: requestData,
    });
  },
  GetReligionWithoutCheckDeviceId: () => {
    const requestData = { ...mainParams };
    console.log("GetReligionWithoutCheckDeviceId");
    return axiosInstance.get("/GetReligionWithoutCheckDeviceId", {
      params: requestData,
    });
  },
  GetLeaveCodeWithoutCheckDevice: () => {
    const requestData = { ...mainParams };
    console.log("GetLeaveCodeWithoutCheckDevice");
    return axiosInstance.get("/GetLeaveCodeWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetSalarySchemaWithoutCheckDevice: () => {
    const requestData = { ...mainParams };
    console.log("GetSalarySchemaWithoutCheckDevice");
    return axiosInstance.get("/GetSalarySchemaWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetAddressWithoutCheckDevice: () => {
    const requestData = { ...mainParams };
    console.log("GetAddressWithoutCheckDevice");
    return axiosInstance.get("/GetAddressWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetCountryWithoutCheckDevice: () => {
    const requestData = { ...mainParams };
    console.log("GetCountryWithoutCheckDevice");
    return axiosInstance.get("/GetCountryWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetLeavePolicyWithoutCheckDevice: () => {
    console.log("GetLeavePolicyWithoutCheckDevice");
    const requestData = { ...mainParams };
    return axiosInstance.get("/GetLeavePolicyWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetJobGradeWithoutCheckDevice: () => {
    console.log("GetJobGradeWithoutCheckDevice");
    const requestData = { ...mainParams };
    return axiosInstance.get("/GetJobGradeWithoutCheckDevice", {
      params: requestData,
    });
  },
  GetEmployeeWeb: (empno) => {
    console.log("GetEmployeeWeb");
    console.log(empno);
    
    mainParams.merchantNo = localStorage.getItem('merchant_no')
    console.log(mainParams.merchantNo);
    const requestData = { ...mainParams, empno };
    return axiosInstance.get("/GetEmployeeWeb", {
      params: requestData,
    });
  },
  GetForeignEmployeeWeb: (empno) => {
    console.log("GetForeignEmployeeWeb");
    const requestData = { ...mainParams, empno: empno };
    return axiosInstance.get("/GetForeignEmployeeWeb", {
      params: requestData,
    });
  },
  GetWorkGroupWithoutCheckDevice: (empno) => {
    console.log("GetWorkGroupWithoutCheckDevice");
    const requestData = { ...mainParams, empno: empno };
    return axiosInstance.get("/GetWorkGroupWithoutCheckDevice", {
      params: requestData,
    });
  },

  GetLeaveHistory: (empno) => {
    console.log(empno);
    console.log("GetLeaveHistory");
    const requestData = { ...mainParams, empno: empno };
    return axiosInstance.get("/GetLeaveHistory", {
      params: requestData,
    });
  },

  async fetchCompanyList({ commit }) {
    try {
      commit("setLoading", true);
      await api
        .GetUserRegisteredMerchant({
          merchantNo: '',
          userId: localStorage.getItem('userId')
        })
        .then((response) => {
          let merchantProfile = response.data.MerchantProfile;
          localStorage.setItem('merchant_no', merchantProfile[0].merchant_no)
          localStorage.getItem('merchant_no')
          console.log(localStorage.getItem('merchant_no'))
          console.log("fetchMerchantList", merchantProfile);
          commit("setMerchantList", merchantProfile);
          commit("setLoading", false);
        });
    } catch (error) {
      // Handle errors
      commit("setLoading", false);
    }
  },

  // ------------------------- POST Methods -------------------------

  refreshEmpTimeLog: (data) => { //lxy
    const requestData = { 
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appId: mainParams.appId,
      merchantNo: mainParams.merchantNo,
      mLoginId: mainParams.mLoginId,
      ...data
    };
    console.log("refreshEmpTimeLog");
    return axiosInstance.post("/refreshEmpTimeLog", 
      requestData
      );
  },
  AddEmployeeTimeLog: (data) => { //lxy
    const requestData = { 
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appId: mainParams.appId,
      merchantNo: mainParams.merchantNo,
      mLoginId: mainParams.mLoginId,
      isTimeHistory: "true",
      inlock: "false",
      ...data
    };
    console.log("AddEmployeeTimeLog");
    return axiosInstance.post("/AddEmployeeTimeLog", 
      requestData
      );
  },
  UpdateEmployeeTimeLog: (data) => { //lxy
    const requestData = { 
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appId: mainParams.appId,
      merchantNo: mainParams.merchantNo,
      mLoginId: mainParams.mLoginId,
      isTimeHistory: "true",
      inlock: "false",
      ...data
    };
    console.log("UpdateEmployeeTimeLog");
    return axiosInstance.post("/UpdateEmployeeTimeLog", 
      requestData
      );
  },
  RemoveEmpTimeLog: (data) => { //lxy
    const requestData = {
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appId: mainParams.appId,
      merchantNo: mainParams.merchantNo,
      mLoginId: mainParams.mLoginId,
      ...data,
    };
    console.log("RemoveEmpTimeLog", data);
    return axiosInstance.post("/RemoveEmpTimeLog", requestData);
  },
  UpdateTimeShtApprove: (data) => { //lxy
    const requestData = {
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appId: mainParams.appId,
      merchantNo: mainParams.merchantNo,
      mLoginId: mainParams.mLoginId,
      ...data,
    };
    console.log("UpdateTimeShtApprove", data);
    return axiosInstance.post("/UpdateTimeShtApprove", requestData);
  },
  ApprvEmpLeave: (data) => { //lxy
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("ApprvEmpLeave", data);
    return axiosInstance.post("/ApprvEmpLeaveWithoutCheckDevice", requestData);
  },
  ReqEmpLeave: (data) => { //lxy
    const requestData = {
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appId: mainParams.appId,
      merchantNo: mainParams.merchantNo,
      mLoginId: mainParams.mLoginId,
      ...data,
    };
    console.log("ReqEmpLeave", data);
    return axiosInstance.post("/ReqEmpLeaveForAdmin", requestData);
  },
  UpdateEmpLeave: (data) => { //lxy
    const requestData = {
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appId: mainParams.appId,
      merchantNo: mainParams.merchantNo,
      mLoginId: mainParams.mLoginId,
      ...data,
    };
    console.log("UpdateEmpLeave", data);
    return axiosInstance.post("/UpdateEmpLeave", requestData);
  },
  SaveWithEmpNo: (data) => { //lxy
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("SaveEmpExpenses", data);
    return axiosInstance.post("/SaveWithEmpNo", requestData);
  },
  SaveExpPicture: (data) => { //lxy
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("SaveExpPicture", data);
    return axiosInstance.post("/SaveExpPicture", requestData);
  },
  UpdateExp: (data) => { //lxy
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("UpdateExp", data);
    return axiosInstance.post("/UpdateExp", requestData);
  },
  DeleteEmpLeave: (data) => { //lxy
    const requestData = {
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appId: mainParams.appId,
      merchantNo: mainParams.merchantNo,
      mLoginId: mainParams.mLoginId,
      ...data,
    };
    console.log("DeleteEmpLeave", data);
    return axiosInstance.post("/DeleteEmpLeaveWithEmpNo", requestData);
  },
  UpdateEndJourStatus: (data) => { //lxy
    const requestData = {
      wsCodeCrypt: mainParams.wsCodeCrypt,
      caUid: mainParams.caUid,
      caPwd: mainParams.caPwd,
      appId: mainParams.appId,
      merchantNo: mainParams.merchantNo,
      mLoginId: mainParams.mLoginId,
      ...data,
    };
    console.log("UpdateEndJourStatus", data);
    return axiosInstance.post("/UpdateEndJourStatusWeb", requestData);
  },
  RemoveExpPicture: (data) => { //lxy
    const requestData = {
      ... mainParams,
      ...data,
    };
    console.log("RemoveExpPicture", data);
    return axiosInstance.post("/RemoveExpPicture", requestData);
  },
  DeleteExp: (data) => { //lxy
    const requestData = {
      ... mainParams,
      ...data,
    };
    console.log("DeleteExp", data);
    return axiosInstance.post("/DeleteExp", requestData);
  },
  ProcessToMonthEnd: (data) => { //lxy
    const requestData = {
      ... mainParams,
      ...data,
    };
    console.log("ProcessToMonthEnd", data);
    return axiosInstance.post("/ProcessToMonthEnd", requestData);
  },
  AddUpdateEmployeeWithoutCheckDevice: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateEmployeeWithoutCheckDevice", requestData);
    return axiosInstance.post(
      "/AddUpdateEmployeeWithoutCheckDevice",
      requestData
    );
  },

  UpdateEmployeePhotoWithoutCheckDevice: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("UpdateEmployeePhotoWithoutCheckDevice", requestData);
    return axiosInstance.post(
      "/UpdateEmployeePhotoWithoutCheckDevice",
      requestData
    );
  },
  AddUpdateDepartment: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateDepartment", requestData);
    return axiosInstance.post("/AddUpdateDepartment", requestData);
  },
  AddUpdateCountry: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateCountry", requestData);
    return axiosInstance.post("/AddUpdateCountry", requestData);
  },
  AddUpdateJobGrade: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateJobGrade", requestData);
    return axiosInstance.post("/AddUpdateJobGrade", requestData);
  },
  AddUpdateCostCenter: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateCostCenter", requestData);
    return axiosInstance.post("/AddUpdateCostCenter", requestData);
  },
  AddUpdateRace: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateRace", requestData);
    return axiosInstance.post("/AddUpdateRace", requestData);
  },
  AddUpdateNationality: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateNationality", requestData);
    return axiosInstance.post("/AddUpdateNationality", requestData);
  },
  AddUpdateReligion: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateReligion", requestData);
    return axiosInstance.post("/AddUpdateReligion", requestData);
  },
  AddUpdateBranch: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateBranch", requestData);
    return axiosInstance.post("/AddUpdateBranch", requestData);
  },

  AddUpdateLeaveCode: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateLeaveCode", requestData);
    return axiosInstance.post("/AddUpdateLeaveCode", requestData);
  },
  AddUpdateSalaryScheme: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateSalaryScheme", requestData);
    return axiosInstance.post("/AddUpdateSalaryScheme", requestData);
  },
  AddUpdateAddress: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateAddress", requestData);
    return axiosInstance.post("/AddUpdateAddress", requestData);
  },

  UpdateExpiryDatesToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("UpdateExpiryDatesToEmployee", requestData);
    return axiosInstance.post("/UpdateExpiryDatesToEmployee", requestData);
  },
  UpdateIsForeignerToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("UpdateIsForeignerToEmployee", requestData);
    return axiosInstance.post("/UpdateIsForeignerToEmployee", requestData);
  },

  DeleteLeaveCode: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteLeaveCode", data);
    return axiosInstance.post("/DeleteLeaveCode", requestData);
  },
  DeleteCostCenter: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteCostCenter", data);
    return axiosInstance.post("/DeleteCostCenter", requestData);
  },
  DeleteSalaryScheme: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteSalaryScheme", data);
    return axiosInstance.post("/DeleteSalaryScheme", requestData);
  },
  AddUpdateLeavePolicy: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateLeavePolicy", data);
    return axiosInstance.post("/AddUpdateLeavePolicy", requestData);
  },
  DeleteLeavePolicy: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteLeavePolicy", data);
    return axiosInstance.post("/DeleteLeavePolicy", requestData);
  },
  DeleteJobGrade: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteJobGrade", data);
    return axiosInstance.post("/DeleteJobGrade", requestData);
  },
  DeleteNationality: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteNationality", data);
    return axiosInstance.post("/DeleteNationality", requestData);
  },
  DeleteReligion: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteReligion", data);
    return axiosInstance.post("/DeleteReligion", requestData);
  },
  AssignEmployeeLeavePolicy: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignEmployeeLeavePolicy", data);
    return axiosInstance.post("/AssignEmployeeLeavePolicy", requestData);
  },

  AssignJobGradeToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignJobGradeToEmployee", data);
    return axiosInstance.post("/AssignJobGradeToEmployee", requestData);
  },
  AssignPayModeToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignPayModeToEmployee", data);
    return axiosInstance.post("/AssignPayModeToEmployee", requestData);
  },
  AssignCountryToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignCountryToEmployee", data);
    return axiosInstance.post("/AssignCountryToEmployee", requestData);
  },
  AssignCostCenterToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignCostCenterToEmployee", data);
    return axiosInstance.post("/AssignCostCenterToEmployee", requestData);
  },

  AssignNationalityToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignNationalityToEmployee", data);
    return axiosInstance.post("/AssignNationalityToEmployee", requestData);
  },
  AssignReligionToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignReligionToEmployee", data);
    return axiosInstance.post("/AssignReligionToEmployee", requestData);
  },
  AssignBankAddressToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignBankAddressToEmployee", data);
    return axiosInstance.post("/AssignBankAddressToEmployee", requestData);
  },
  AssignStatutorySettingToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignStatutorySettingToEmployee", data);
    return axiosInstance.post("/AssignStatutorySettingToEmployee", requestData);
  },
  AssignBankAccountToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignBankAccountToEmployee", data);
    return axiosInstance.post("/AssignBankAccountToEmployee", requestData);
  },

  AssignSalarySchemeToEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AssignSalarySchemeToEmployee", data);
    return axiosInstance.post("/AssignSalarySchemeToEmployee", requestData);
  },
  DeleteLeaveHistory: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteLeaveHistory", data);
    return axiosInstance.post("/DeleteLeaveHistory", requestData);
  },
  AddUpdateLeaveHistory: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("AddUpdateLeaveHistory", data);
    return axiosInstance.post("/AddUpdateLeaveHistory", requestData);
  },
  DeleteDepartment: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteDepartment", data);
    return axiosInstance.post("/DeleteDepartment", requestData);
  },
  DeleteRace: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteRace", data);
    return axiosInstance.post("/DeleteRace", requestData);
  },
  Deletebranch: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("Deletebranch", data);
    return axiosInstance.post("/Deletebranch", requestData);
  },
  DeleteAddress: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteAddress", data);
    return axiosInstance.post("/DeleteAddress", requestData);
  },
  DeleteCountry: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("DeleteCountry", data);
    return axiosInstance.post("/DeleteCountry", requestData);
  },
  RemoveEmployee: (data) => {
    const requestData = {
      ...mainParams,
      ...data,
    };
    console.log("RemoveEmployee", data);
    return axiosInstance.post("/RemoveEmployee", requestData);
  },
};

export { axiosInstance, api };
